import React from 'react'
import {Alert, Cell, Group, List, Panel, PanelHeader, Root, View} from "@vkontakte/vkui";
import {connect as redux_connect} from "react-redux";
import {alertActions, clientActions, navigationActions, popoutActions} from "../../../actions";
import Icon24Settings from '@vkontakte/icons/dist/24/settings';
import {Settings, About, NotificationSettings} from "./panels";


class MoreRoot extends React.Component {
    constructor(props) {
        super(props);

        const defaultState = {
            activePanel: 'home',
            activeView: 'home-view'
        };

        const {activePanel, activeView} = this.props;

        if (!activePanel)
            this.props.dispatch(navigationActions.changePanel(this.props.id, defaultState.activePanel));
        if (!activeView)
            this.props.dispatch(navigationActions.changeView(this.props.id, defaultState.activeView));

        this.name = "USERNAME";
    }

    render = () => (
        <Root activeView={this.props.activeView} id={this.props.id}>
            <View id="home-view" activePanel={this.props.activePanel} popout={this.props.popout}>
                <Panel id='home'>
                    <PanelHeader>Time Club</PanelHeader>
                    <Group>
                        <List>
                            <Cell before={<Icon24Settings />} onClick={this.props.changePanel} data-to={"settings"}>
                                Настройки
                            </Cell>
                        </List>
                    </Group>
                </Panel>

                <Settings
                    id={'settings'}
                    changePanel={this.props.changePanel}
                    logout={this.props.logout}
                    first_name={this.props.clientName}
                />

                <NotificationSettings
                    id={'notifications'}
                    changePanel={this.props.changePanel}
                />



                <About
                    id={'about'}
                    changePanel={this.props.changePanel}
                />
            </View>
        </Root>
    );
}

const mapStateToProps = state => {
    const {popout, navigation, vk} = state;
    return {
        popout: popout.data,
        activeView: navigation.activeViews.more,
        activePanel: navigation.activePanels.more,
        clientName: vk.user ? vk.user.first_name : 'Хей'
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        changePanel: (e) => {
            dispatch(navigationActions.changePanel('more', e.currentTarget.dataset.to));
        },
        changeView: (e) => {
            dispatch(navigationActions.changeView('more', e.currentTarget.dataset.to));
        },
        logout: () => {
            dispatch(popoutActions.showCustomPopout(
                <Alert
                    actions={[{
                        title: 'Выйти',
                        action: () => {dispatch(clientActions.logout())},
                        style: 'destructive',
                        autoclose: true
                    },{
                        title: 'Отмена',
                        autoclose: true,
                        style: 'cancel'
                    }]}
                    onClose={() => {dispatch(alertActions.clear())}}>
                    <h2>Выход</h2>
                    <p>Вы действительно хотите выйти?</p>
                </Alert>))
        }
    }
};

const connectedMoreRoot = redux_connect(
    mapStateToProps,
    mapDispatchToProps
)(MoreRoot);

export default connectedMoreRoot;