import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Avatar, Button, Div, Group, ListItem, Panel, PanelHeader} from '@vkontakte/vkui';
import Client from './Client'
import {alertActions, clientActions, navigationActions, popoutActions} from "../../../../actions";
import {connect as redux_connect} from "react-redux";


const Home = ({ id, changeView, changePanel, vk, loggedIn, printState, logout, tc_client, dispatch, poll_status }) => (
	<Panel id={id}>
		<PanelHeader>Time Club</PanelHeader>
		{vk.user &&
		<Group title="User Data Fetched with VK Connect">
			<ListItem
				before={vk.user.photo_200 ? <Avatar src={vk.user.photo_200}/> : null}
				description={vk.user.city && vk.user.city.title ? vk.user.city.title : ''}
			>
				{`${vk.user.first_name} ${vk.user.last_name}`}<br/>
				{`${vk.user.id}`}
			</ListItem>
		</Group>}

		{poll_status && <Div>{`${poll_status}`}</Div>}
		{tc_client && <Client client={tc_client}/>}

		<Group title="Modal" >
			<Div>
				{!loggedIn &&
				<Button onClick={changeView} data-to="login-view">
					Login
				</Button>
				}
				{loggedIn &&
				<Button onClick={() => {
					dispatch(popoutActions.showCustomPopout(
						<Alert
							actions={[{
								title: 'Выйти',
								action: logout,
								style: 'destructive',
								autoclose: true
							},{
								title: 'Отмена',
								autoclose: true,
								style: 'cancel'
							}]}
							onClose={() => {dispatch(alertActions.clear())}}>
							<h2>Выход</h2>
							<p>Вы действительно хотите выйти?</p>
						</Alert>))
				}}>
					Log out
				</Button>
				}
			</Div>
			<Div>
				<Button onClick={printState}>
					Print State
				</Button>
			</Div>
		</Group>
	</Panel>
);

//TODO
Home.propTypes = {
	id: PropTypes.string.isRequired,
	changePanel: PropTypes.func.isRequired,
	vk: PropTypes.shape({
		user:	PropTypes.shape({
			photo_200: PropTypes.string,
			first_name: PropTypes.string,
			last_name: PropTypes.string,
			city: PropTypes.shape({
				title: PropTypes.string,
			})
		})
	}),
	loggedIn: PropTypes.bool,
	dispatch: PropTypes.func.isRequired
};


const mapStateToProps = state => {
	// console.log(typeof(state), state);
	const { tc_client, tc_credentials, popout, navigation, vk } = state;
	return {
		vk,
		tc_client: tc_client.data,
		poll_status: tc_client.poll_status,
		popout: popout.data,
		activeView: navigation.activeViews.client,
		activePanel: navigation.activePanels.client,
		loggedIn: tc_credentials.loggedIn
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => {
			dispatch(clientActions.logout())
		},
		showSpinner: () => {
			dispatch(popoutActions.showSpinner());
			setTimeout(() => { dispatch(popoutActions.clear()); },3000);
		},
		hideSpinner: () => {
			dispatch(popoutActions.clear())
		},
		changePanel: (e) => {
			dispatch(navigationActions.changePanel('client', e.currentTarget.dataset.to));
		},
		changeView: (e) => {
			dispatch(navigationActions.changeView('client', e.currentTarget.dataset.to));
		},
		dispatch
	}
};

const connectedHome = redux_connect(
	mapStateToProps,
	mapDispatchToProps
)(Home);

export default connectedHome;
