import React from 'react';
import {Cell, Group, HeaderButton, IOS, Panel, PanelHeader, Switch, List} from "@vkontakte/vkui";
import PropTypes from "prop-types";
import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";
import {platform} from "@vkontakte/vkui/src/lib/platform";

const osname = platform();


const NotificationSettings = ({id, changePanel}) => (
        <Panel id={id}>
            <PanelHeader left={<HeaderButton onClick={changePanel} data-to="settings">
                {osname === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
            </HeaderButton>}
            >
                Уведомления
            </PanelHeader>

            <Group title={'VK'}>
                <List>
                    <Cell asideContent={<Switch/>}>
                        Уведомления в ВК
                    </Cell>
                </List>
            </Group>

            <Group title={'Email'}>
                <List>
                    <Cell asideContent={<Switch />}>
                        Все письма
                    </Cell>
                    <Cell asideContent={<Switch />}>
                        Уведомления
                    </Cell>
                    <Cell asideContent={<Switch />}>
                        Рассылка
                    </Cell>
                </List>
            </Group>

        </Panel>
    );

NotificationSettings.propTypes = {
    id: PropTypes.string.isRequired,
    changePanel: PropTypes.func.isRequired,
    // logout: PropTypes.func.isRequired,
    // first_name: PropTypes.string.isRequired
};

export default NotificationSettings;