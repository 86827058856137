import React from 'react'
import {Root, View} from "@vkontakte/vkui";
import Home from './panels/Home';
import Persik from './panels/Persik';
// import UserAction from './panels/UserAction';
import Login from './Login';
import {clientActions, navigationActions, popoutActions} from "../../../actions";
import {connect as redux_connect} from "react-redux";

class ClientRoot extends React.Component {
    constructor(props) {
        super(props);

        const defaultState = {
            activePanel: 'home',
            activeView: 'home-view'
        };

        const {activePanel, activeView} = this.props;

        if (!activePanel)
            this.props.dispatch(navigationActions.changePanel(this.props.id, defaultState.activePanel));
        if (!activeView)
            this.props.dispatch(navigationActions.changeView(this.props.id, defaultState.activeView));
    }

    componentDidMount() {
        if (this.props.tc_credentials.loggedIn && !this.props.tc_client.data)
            this.props.dispatch(clientActions.get_me());
    }

    changePanel = (e) => {
        // this.setState({ activePanel: e.currentTarget.dataset.to })
        this.props.dispatch(navigationActions.changePanel(this.props.id, e.currentTarget.dataset.to));
    };

    changeView = (e) => {
        // this.setState({ activeView: e.currentTarget.dataset.to })
        this.props.dispatch(navigationActions.changeView(this.props.id, e.currentTarget.dataset.to));
    };

    printState = () => {
        console.log(this.state);
        console.log(this.props);
    };

    render() {
        return (
            <Root activeView={this.props.activeView} id={this.props.id}>
                <View id="home-view" activePanel={this.props.activePanel} popout={this.props.popout}>
                    <Home id="home" printState={this.printState} />
                    <Persik id="persik" go={this.changePanel} />
                    {/*<UserAction id="check-user-actions" go={this.changePanel} />*/}
                </View>
                <Login id="login-view" />
            </Root>
        );
    }
}

const mapStateToProps = state => {
    // console.log(typeof(state), state);
    const { tc_client, tc_credentials, popout, navigation, vk } = state;
    return {
        tc_client,
        tc_credentials,
        popout: popout.data,
        activeView: navigation.activeViews.client,
        activePanel: navigation.activePanels.client,
        vk
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(clientActions.logout())
        },
        showSpinner: () => {
            dispatch(popoutActions.showSpinner());
            setTimeout(() => { dispatch(popoutActions.clear()); },3000);
        },
        hideSpinner: () => {
            dispatch(popoutActions.clear())
        },
        dispatch
    }
};

const connectedClientRoot = redux_connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientRoot);

export default connectedClientRoot;