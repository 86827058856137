import {clientConstants, navigationConstants} from "../constants";

export function navigation(state = {
    activeStory: 'client',
    activeViews: {
        client: 'home-view',
        clubs: 'home-view',
        market: 'home-view',
        notifications: 'home-view',
        more: 'home-view'
    },
    activePanels: {
        client: 'home',
        clubs: 'home',
        market: 'home',
        notifications: 'home',
        more: 'home'
    }
}, action) {
    let views = {...state.activeViews};
    let panels = {...state.activePanels};
    switch (action.type) {
        case navigationConstants.CHANGE_STORY:
            return {
                ...state,
                activeStory: action.dst
            };
        case navigationConstants.CHANGE_PANEL:
            panels[action.story] = action.dst;
            return {
                ...state,
                activePanels: panels
            };
        case navigationConstants.CHANGE_VIEW:
            views[action.story] = action.dst;
            return {
                ...state,
                activeViews: views
            };
        case clientConstants.LOGIN_SUCCESS:
            views.client = 'home-view';
            return {
                ...state,
                activeViews: views
            };
        case clientConstants.LOGOUT:
            views.client = 'home-view';
            panels.client = 'home';
            return {
              activeStory: 'client',
              activeViews: views,
              activePanels: panels
            };
        default:
            return state
    }
}