import {alertConstants} from "../constants";

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message, dispatch) {
    return { type: alertConstants.ERROR, message, dispatch };
}

function clear() {
    return { type: alertConstants.CLEAR };
}