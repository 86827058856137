import {authHeader} from '../helpers';

export const API = {
    vk_auth,
    logout,
    get_me,
    check_poll
};

const URL = 'http://dev.timeclub24.com:5000';

const request = async (path, options) => {
    const unpack = (response) => {
        return response.json().then(json => {
            return {data: json, response: response};
        })
    };
    const handler = (obj) => {
        const { response, data } = obj;
        if (response.status === 401) {
            // console.log(data.err);
            if (data.err.includes('token_expired')) {
                return refresh_token().then(() => {
                    options.headers['Authorization'] = authHeader()['Authorization'];
                    return fetch(`${URL}/${path}`, options).then(unpack);
                })
            }
        }
        return obj;
    };
    try {
        return await fetch(`${URL}/${path}`, options)
            .then(unpack)
            .then(handler);
    } catch(err) {
        // console.log("ERR:", err);
        throw err;
    }
};

function vk_auth(vk_fetchedUser, vk_fetchedPhone) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            vk_user_id: vk_fetchedUser.id,
            vk_mobile_phone: vk_fetchedPhone.phone_number,
            vk_signature: vk_fetchedPhone.sign
        })
    };

    return request('auth/', requestOptions)
        .then(handleResponse)
        .then(credentials => {
            // login successful if there's a jwt token in the response
            // console.log(credentials);
            if (credentials.access_token && credentials.refresh_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('credentials', JSON.stringify(credentials));
            }

            return credentials;
        });
}

function refresh_token() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader('refresh')
        }
    };

    return fetch(`${URL}/auth/refresh`, requestOptions)
        .then((response) => {
            return response.json().then(json => {
                return {data: json, response: response};
            })})
        .then(handleResponse)
        .then(credentials => {
            if (credentials.access_token && credentials.refresh_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('credentials', JSON.stringify(credentials));
            }

            return credentials;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('credentials');
}

function get_me() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return request('client/me', requestOptions)
        .then(handleResponse);
}

function check_poll(poll_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json'}
    };

    return request('vk/poll?poll_id=' + poll_id, requestOptions)
        .then(handleResponse);
}

// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };
//
//     return request('users/${user.id}', requestOptions).then(handleResponse);
// }

function handleResponse(obj) {
    const { response, data } = obj;
    if (!response.ok) {
        throw (data && data.err) || response.statusText;
    }
    return data.data;
}