import {alertConstants, popoutConstants} from "../constants";
import {alertActions} from "../actions/alert.actions";
// noinspection ES6CheckImport
import {ScreenSpinner, Alert} from "@vkontakte/vkui";
import React from "react";

export function popout(state = { data: null }, action) {
    switch (action.type) {
        case popoutConstants.SPINNER_SHOW:
            return {
                data: <ScreenSpinner />
            };
        case alertConstants.SUCCESS:
            return {
                type: 'alert-success',
                message: action.message
            };
        case popoutConstants.CUSTOM_SHOW:
            return {
                data: action.popout
            };
        case alertConstants.ERROR:
            return {
                data: <Alert
                    actions={[{
                        title: 'Закрыть',
                        autoclose: true,
                        style: 'destructive'
                    }]}
                    onClose={() => {action.dispatch(alertActions.clear())}}
                >
                    <h2>Произошла ошибка</h2>
                    <p>{`${action.message}`}</p>
                </Alert>
            };
        case popoutConstants.CLEAR:
        case alertConstants.CLEAR:
            return {
                data: null
            };
        default:
            return state
    }
}