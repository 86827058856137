import { clientConstants} from "../constants";

const credentials = JSON.parse(localStorage.getItem('credentials'));

const initialState = {
    loggedIn: Boolean(credentials && credentials.access_token),
    ...credentials
};


export function tc_credentials(state = initialState, action) {
    switch (action.type) {
        case clientConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
            };
        case clientConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                ...action.credentials
            };
        case clientConstants.LOGIN_FAILURE:
            return {};
        case clientConstants.LOGOUT:
            return {};
        default:
            return state
    }
}