import {vkConstants} from "../constants";

export function vk(state = {
    user: null,
    phone: null
}, action) {
    switch (action.type) {
        case vkConstants.USER_INFO:
            return {
                ...state,
                user: action.data
            };
        case vkConstants.PHONE_NUMBER:
            return {
                ...state,
                phone: action.data
            };
        default:
            return state
    }
}