export const clientConstants = {
    REGISTER_REQUEST: 'CLIENTS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'CLIENTS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'CLIENTS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'CLIENTS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'CLIENTS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'CLIENTS_LOGIN_FAILURE',

    TOKEN_REFRESH_REQUEST: 'CLIENTS_TOKEN_REFRESH_REQUEST',
    TOKEN_REFRESH_SUCCESS: 'CLIENTS_TOKEN_REFRESH_SUCCESS',
    TOKEN_REFRESH_FAILURE: 'CLIENTS_TOKEN_REFRESH_FAILURE',

    LOGOUT: 'CLIENTS_LOGOUT',

    PROFILE_REQUEST: 'CLIENT_PROFILE_REQUEST',
    PROFILE_SUCCESS: 'CLIENTS_PROFILE_SUCCESS',
    PROFILE_FAILURE: 'CLIENTS_PROFILE_FAILURE',

    LOAD_CREDENTIALS: 'CLIENT_LOAD_CREDENTIALS',

    // Polls
    POLL_CHECK_REQUEST: 'CLIENT_POLL_CHECK_REQUEST',
    POLL_CHECK_SUCCESS: 'CLIENT_POLL_CHECK_SUCCESS',
    POLL_CHECK_FAILURE: 'CLIENT_POLL_CHECK_FAILURE',
};
