import React from 'react'
import {Root, View, Panel, Button, PanelHeader} from "@vkontakte/vkui";
import {connect as redux_connect} from "react-redux";
import {navigationActions} from "../../../actions";

class MarketRoot extends React.Component {
    constructor(props) {
        super(props);

        const defaultState = {
            activePanel: 'home',
            activeView: 'home-view'
        };

        const {activePanel, activeView} = this.props;

        if (!activePanel)
            this.props.dispatch(navigationActions.changePanel(this.props.id, defaultState.activePanel));
        if (!activeView)
            this.props.dispatch(navigationActions.changeView(this.props.id, defaultState.activeView));
    }

    render = () => (
        <Root activeView={this.props.activeView} id={this.props.id}>
            <View id="home-view" activePanel={this.props.activePanel} popout={this.props.popout}>
                <Panel id='home'>
                    <PanelHeader>Time Club</PanelHeader>
                    Hello World! <br/>
                    <Button onClick={this.props.changePanel} data-to='next'> Next</Button>
                </Panel>
                <Panel id='next'>
                    <PanelHeader>Time Club</PanelHeader>
                    Next View <br/>
                    <Button onClick={this.props.changePanel} data-to='home'> Prev</Button>
                </Panel>
            </View>
        </Root>
    );
}

const mapStateToProps = state => {
    const {popout, navigation} = state;
    return {
        popout: popout.data,
        activeView: navigation.activeViews.market,
        activePanel: navigation.activePanels.market,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        changePanel: (e) => {
            dispatch(navigationActions.changePanel('market', e.currentTarget.dataset.to));
        },
        changeView: (e) => {
            dispatch(navigationActions.changeView('market', e.currentTarget.dataset.to));
        },
    }
};

const connectedMarketRoot = redux_connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketRoot);

export default connectedMarketRoot;