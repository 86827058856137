import React from 'react';
// noinspection ES6CheckImport
import {Epic, Tabbar, TabbarItem} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import {connect as redux_connect} from 'react-redux';
import {navigationActions} from "../actions/navigation.actions";

import Icon28User from '@vkontakte/icons/dist/28/user';
import Icon28Place from '@vkontakte/icons/dist/28/place';
// import Icon24Market from '@vkontakte/icons/dist/24/market';
// import Icon28Delete from '@vkontakte/icons/dist/28/delete';
// import Icon28Notification from '@vkontakte/icons/dist/28/notification';
import Icon28More from '@vkontakte/icons/dist/28/more';
import connect from "@vkontakte/vkui-connect";
import {clientActions} from "../actions/client.actions";
import {ClientRoot, ClubsRoot, MarketRoot, MoreRoot, NotificationsRoot} from "./stories";


class App extends React.Component {

    componentDidMount() {
        connect.subscribe((e) => {
            switch (e.detail.type) {
                case 'VKWebAppGetUserInfoResult':
                //     // this.setState({ vk_fetchedUser: e.detail.data });
                    this.props.dispatch(e.detail);
                    break;
                case 'VKWebAppGetPhoneNumberResult':
                    // TODO: change app key and remove
                    if (!e.detail.data.sign) {
                        e.detail.data.sign = 'xuYdjz6rCFGrLVQXtH4XZyj35htsbPGxOgiVBEvPFC4';
                    }
                    this.props.dispatch(e.detail);
                    if (this.props.vk.user && this.props.vk.phone)
                    {
                        this.props.dispatch(clientActions.vk_auth(this.props.vk))
                    }
                    break;
                case 'VKWebAppGetPhoneNumberFailed':
                    console.log(e.detail);
                    break;
                case 'VKWebAppUpdateConfig':
                    console.log("VKWebAppUpdateConfig: " + e.detail.schema);
                    let schemeAttribute = document.createAttribute('scheme');
                    schemeAttribute.value = e.detail.data.scheme ? e.detail.data.scheme : 'client_light';
                    document.body.attributes.setNamedItem(schemeAttribute);
                    break;
                default:
            }
        });
        connect.send('VKWebAppGetUserInfo', {});
    }

    onStoryChange  = (e) => {
        this.props.dispatch(navigationActions.changeStory(e.currentTarget.dataset.story));
    };

	render() {
		return (<div>{this.props.schema}
            <Epic activeStory={this.props.activeStory} tabbar={
                <Tabbar>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={this.props.activeStory === 'client'}
                        data-story="client"
                    ><Icon28User/></TabbarItem>
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={this.props.activeStory === 'clubs'}
                        data-story='clubs'
                    ><Icon28Place/></TabbarItem>
                    {/*<TabbarItem*/}
                    {/*    onClick={this.onStoryChange}*/}
                    {/*    selected={this.props.activeStory === 'market'}*/}
                    {/*    data-story='market'*/}
                    {/*><Icon28Delete/></TabbarItem>*/}
                    {/*<TabbarItem*/}
                    {/*    onClick={this.onStoryChange}*/}
                    {/*    selected={this.props.activeStory === 'notifications'}*/}
                    {/*    data-story='notifications'*/}
                    {/*><Icon28Notification/></TabbarItem>*/}
                    <TabbarItem
                        onClick={this.onStoryChange}
                        selected={this.props.activeStory === 'more'}
                        data-story='more'
                    ><Icon28More/></TabbarItem>
                </Tabbar>
            }>
                <ClientRoot id='client' />
                <ClubsRoot id='clubs' />
                <MarketRoot id='market' />
                <NotificationsRoot id='notifications' />
                <MoreRoot id='more' />
            </Epic></div>
		);
	}
}

const mapStateToProps = state => {
    const {navigation, vk} = state;
    return {
        activeStory: navigation.activeStory,
        vk
    };
};

const connectedApp = redux_connect(
	mapStateToProps,
	// mapDispatchToProps
)(App);
// export { connectedApp as App };
export default connectedApp;
