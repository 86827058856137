import {combineReducers} from 'redux';

import {tc_client} from "./tc_clients.reducer";
import {tc_credentials} from "./tc_credentials.reducer";
import {popout} from "./popout.reducer";
import {navigation} from "./navigation.reducer";
import {vk} from "./vk.reducer";


const rootReducer = combineReducers({
    tc_client,
    tc_credentials,
    popout,
    navigation,
    vk
});

export default rootReducer;