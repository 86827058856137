import {Cell, CellButton, Group, HeaderButton, IOS, List, Panel, PanelHeader} from "@vkontakte/vkui";
import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";
import Icon24Notification from "@vkontakte/icons/dist/24/notification";
import Icon24User from "@vkontakte/icons/dist/24/user";
// import Icon24Work from "@vkontakte/icons/dist/24/work";
import Icon24Info from "@vkontakte/icons/dist/24/info";
import React from "react";
import PropTypes from "prop-types";
import {platform} from "@vkontakte/vkui/src/lib/platform";

const osname = platform();

const Settings = ({id, changePanel, logout, first_name}) => (
    <Panel id={id}>
        <PanelHeader
            left={<HeaderButton onClick={changePanel} data-to="home">
                {osname === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
            </HeaderButton>}
        >Настройки</PanelHeader>
        <Group>
            <List>
                <Cell
                    before={<Icon24Notification />}
                    onClick={changePanel}
                    data-to={"notifications"}>
                    Уведомления
                </Cell>

                <Cell
                    before={<Icon24User />}
                    // onClick={changePanel}
                    data-to={"user"}>
                    Учетная запись
                </Cell>

                {/*<Cell*/}
                    {/*before={<Icon24Work/>}*/}
                    {/*href={"https://timeclub24.ru/job/"}*/}
                    {/*target={"_blank"}>*/}
                    {/*{first_name}, хочешь в команду ТК?*/}
                {/*</Cell>*/}
            </List>
        </Group>

        <Group>
            <List>
                <Cell
                    before={<Icon24Info />}
                    onClick={changePanel}
                    data-to={"about"}>
                    О приложении
                </Cell>
            </List>
        </Group>

        <Group>
            <List>
                <CellButton
                    level="danger"
                    onClick={logout}>
                    Выйти
                </CellButton>
            </List>
        </Group>
    </Panel>
);

Settings.propTypes = {
    id: PropTypes.string.isRequired,
    changePanel: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    first_name: PropTypes.string.isRequired
};

export default Settings;