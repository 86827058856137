import {navigationConstants} from "../constants";

export const navigationActions = {
    changeStory,
    changeView,
    changePanel
};

function changeStory(dst) {
    return { type: navigationConstants.CHANGE_STORY, dst}
}

function changeView(story, dst) {
    return { type: navigationConstants.CHANGE_VIEW, story, dst}
}

function changePanel(story, dst) {
    return { type: navigationConstants.CHANGE_PANEL, story, dst}
}