import React, {Component} from 'react';
import {Button, Div, Group, HeaderButton, IOS, Panel, PanelHeader, platform, View} from '@vkontakte/vkui';
import Icon24Phone from '@vkontakte/icons/dist/24/phone';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import Icon24Spinner from '@vkontakte/icons/dist/24/spinner';
import connect from '@vkontakte/vkui-connect';
import {navigationActions, popoutActions} from '../../../actions'
import {connect as redux_connect} from "react-redux";
import PropTypes from 'prop-types';

const OSName = platform();

const getPhone = function () {
    connect.send('VKWebAppGetPhoneNumber', {});
};

class Login extends Component {
    render() {
        let {id, close, popout, showSpinner} = this.props;
        return (<View activePanel='login' id={id} popout={popout}>
                <Panel id='login'>
                    <PanelHeader
                        left={<HeaderButton onClick={close}>
                            {OSName === IOS ? 'Отмена' : <Icon24Cancel/>}
                        </HeaderButton>}
                    >
                        Войти
                    </PanelHeader>
                    <Group>
                        <Div>
                            Тут будут описание что делает кнопка ниже.<br/>
                            Типа все bien, она отправляет номер телефона из контакта в ТК и так я могу залогиниться<br/>
                            Еще можно написать, что все секурно и все шифруется (отчасти)
                        </Div>
                    </Group>
                    <Group>
                        <Div style={{display: 'flex'}}>
                            <Button size="l" stretched before={<Icon24Phone/>} onClick={getPhone}>
                                Get Phone
                            </Button>
                        </Div>
                    </Group>
                    <Group>
                        <Div style={{display: 'flex'}}>
                            <Button size="l" stretched before={<Icon24Spinner/>} onClick={showSpinner}>
                                Запустить спинер
                            </Button>
                        </Div>
                    </Group>
                </Panel>
            </View>
        )
    }
}

Login.propTypes = {
    id: PropTypes.string.isRequired,
    popout: PropTypes.object,
    showSpinner: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        popout: state.popout.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSpinner: () => {
            dispatch(popoutActions.showSpinner());
            setTimeout(() => { dispatch(popoutActions.clear()); },3000);
        },
        close: () => {
            dispatch(navigationActions.changeView('client', 'home-view'));
        }
    }
};

const connectedLogin = redux_connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default connectedLogin;