export function authHeader(type='access') {
    // return authorization header with jwt token
    let credentials = JSON.parse(localStorage.getItem('credentials'));

    if (!credentials)
        return {};
    if (type==='access' && credentials.access_token)
        return { 'Authorization': 'Bearer ' + credentials.access_token };
    else if (type==='refresh' && credentials.refresh_token)
        return { 'Authorization': 'Bearer ' + credentials.refresh_token };
    else
        return {};
}