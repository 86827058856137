import { clientConstants} from "../constants";

export function tc_client(state = {}, action) {
    switch (action.type) {
        case clientConstants.PROFILE_SUCCESS:
            return {
                data: action.data.client
            };
        case clientConstants.LOGOUT:
            return {};
        case clientConstants.POLL_CHECK_SUCCESS:
            return { ...state, poll_status: action.data.status};
        default:
            return state
    }
}