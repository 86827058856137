import React from 'react'
import {PanelHeader, HeaderButton, IOS, Panel, Group, List, Cell, Footer} from "@vkontakte/vkui";
import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";
import {platform} from "@vkontakte/vkui/src/lib/platform";
import PropTypes from "prop-types";
import Settings from "./Settings";
import tc_logo from "../../../../img/tc_logo_orange.png";

const osname = platform();

const About = ({id, changePanel}) => (
    <Panel id={id}>
        <PanelHeader
            left={<HeaderButton onClick={changePanel} data-to="settings">
            {osname === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
            </HeaderButton>}
        >
            О приложении
        </PanelHeader>
        <img className="AboutLogo" src={tc_logo} alt="Persik The Cat"/>
        {process.env.REACT_APP_VERSION &&
            <Footer >Version {process.env.REACT_APP_VERSION}</Footer>
        }
        <Group>
            <List>
                <Cell>
                    Условия использования
                </Cell>
                <Cell>
                    Публичная оферта
                </Cell>
            </List>
        </Group>
    </Panel>
);

Settings.propTypes = {
    id: PropTypes.string.isRequired,
    changePanel: PropTypes.func.isRequired,
    // logout: PropTypes.func.isRequired,
    // first_name: PropTypes.string.isRequired
};

export default About;