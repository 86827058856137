import {popoutConstants} from "../constants";

export const popoutActions = {
    showSpinner,
    showCustomPopout,
    clear
};

function showSpinner() {
    return { type: popoutConstants.SPINNER_SHOW }
}

function clear() {
    return { type: popoutConstants.CLEAR }
}

function showCustomPopout(popout) {
    return { type: popoutConstants.CUSTOM_SHOW, popout}
}