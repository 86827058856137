import {Group, ListItem} from "@vkontakte/vkui";
import React from "react";
import PropTypes from "prop-types";


const Client = ({client}) => (
    <Group title="User data fetched with TC API" >
        <ListItem>
            {`${client.first_name} ${client.last_name}`}<br/>
            Карта №{`${client.card.number}`}<br/>
            Баланс: {`${client.balance_rub}`} ₽ {`${client.balance_minutes}`} Минуточек
        </ListItem>
    </Group>
);


Client.propTypes = {
    client: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        card: PropTypes.shape({
            number: PropTypes.string
        }),
        balance_rub: PropTypes.number,
        balance_minutes: PropTypes.number
    })
};

export default Client;