import {clientConstants} from "../constants";
import {API} from '../services/api.timeclub'
import {popoutActions} from "./popout.actions";
import {alertActions} from "./alert.actions";

export const clientActions = {
    vk_auth,
    get_me,
    logout,
};


function vk_auth(vk) {
    return dispatch => {
        dispatch(request());
        dispatch(popoutActions.showSpinner());

        API.vk_auth(vk.user, vk.phone)
            .then(
                credentials => {
                    dispatch(success(credentials));
                    dispatch(get_me());
                    // history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error, dispatch));
                }
            )
            .then(
                dispatch(popoutActions.clear())
            );
    };

    function request() { return { type: clientConstants.LOGIN_REQUEST } }
    function success(credentials) { return { type: clientConstants.LOGIN_SUCCESS, credentials } }
    function failure(error) { return { type: clientConstants.LOGIN_FAILURE, error } }
}

function get_me() {
    return dispatch => {
        dispatch(request());

        API.get_me()
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(check_poll('343997603'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error, dispatch));
                }
            );
    };

    function request() { return { type: clientConstants.PROFILE_REQUEST } }
    function success(data) { return { type: clientConstants.PROFILE_SUCCESS, data } }
    function failure(error) { return {type: clientConstants.PROFILE_FAILURE, error } }

}

function check_poll(poll_id) {
    return dispatch => {
        dispatch(request());

        API.check_poll(poll_id)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error, dispatch));
                }
            );
    };

    function request() { return { type: clientConstants.POLL_CHECK_REQUEST } }
    function success(data) { return { type: clientConstants.POLL_CHECK_SUCCESS, data } }
    function failure(error) { return {type: clientConstants.POLL_CHECK_FAILURE, error } }

}

// function get_me() {
//     return dispatch => {
//         dispatch(request());
//
//         API.get_me()
//             .then(
//                 data => {
//                     dispatch(success(data));
//                 },
//                 error => {
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error, dispatch));
//                 }
//             );
//     };
//
//     function request() { return { type: clientConstants.PROFILE_REQUEST } }
//     function success(data) { return { type: clientConstants.PROFILE_SUCCESS, data } }
//     function failure(error) { return {type: clientConstants.PROFILE_FAILURE, error } }
//
// }

function logout() {
    return dispatch => {
        dispatch(success());
        API.logout();
    };

    function success() { return { type: clientConstants.LOGOUT } }
}
